var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-form-control', {
    attrs: {
      "is-disabled": _vm.isDisabled,
      "is-required": _vm.isRequired,
      "is-invalid": _vm.isInvalid,
      "margin-bottom": _vm.isWithoutMarginBottom ? '0' : ['16px', '24px'],
      "is-read-only": _vm.isReadOnly,
      "width": _vm.fullWidth ? '100%' : 'inherit'
    }
  }, [!_vm.isWithoutLabel ? _c('c-form-label', {
    attrs: {
      "font-size": ['14px', '16px'],
      "color": _vm.isInvalid ? 'danger.400' : _vm.value ? 'primary.400' : '#555',
      "font-weight": "400",
      "font-family": "Roboto",
      "padding-left": "8px"
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " "), !_vm.isRequired && _vm.isRequired !== undefined ? _c('c-box', {
    attrs: {
      "as": "span",
      "font-family": "Roboto",
      "font-weight": "400",
      "color": "#C4C4C4",
      "font-size": ['12px', '14px']
    }
  }, [_vm._v(" (Optional) ")]) : _vm._e()], 1) : _vm._e(), _c('BaseInputAutocomplete2', {
    attrs: {
      "options": _vm.options,
      "placeholder": _vm.placeholder,
      "is-loading": _vm.isLoading,
      "is-invalid": _vm.isInvalid,
      "value-label": _vm.optionValue,
      "height": _vm.getSize.input.height,
      "font-size": _vm.getSize.input.fontSize,
      "with-border": _vm.withBorder,
      "show-empty-value": _vm.showEmptyValue
    },
    on: {
      "blur": function blur($event) {
        return _vm.$emit('blur');
      }
    },
    model: {
      value: _vm.modelValue,
      callback: function callback($$v) {
        _vm.modelValue = $$v;
      },
      expression: "modelValue"
    }
  }), _vm.isHelper ? _c('c-form-helper-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "font-weight": ['400', '400'],
      "display": "flex",
      "gap": "8px",
      "align-items": "center",
      "color": _vm.helperTextColor
    }
  }, [_c('c-box', {
    attrs: {
      "display": "flex",
      "gap": "8px",
      "align-items": "center",
      "cursor": _vm.hasHelperTextClick ? 'pointer' : 'auto'
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('helper-text-click');
      }
    }
  }, [_vm.helperTextLogo ? _c('c-box', {
    attrs: {
      "min-height": ['13px', '16px'],
      "min-width": ['13px', '16px'],
      "height": ['13px', '16px'],
      "width": ['13px', '16px']
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": _vm.helperTextLogo,
      "height": "100%",
      "width": "100%",
      "fill": _vm.helperTextColor
    }
  })], 1) : _vm._e(), _vm._v(" " + _vm._s(_vm.helperText) + " ")], 1)], 1) : _vm._e(), !_vm.isDataEmpty(_vm.invalidText) && _vm.isInvalid ? _c('c-form-helper-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "font-weight": ['400', '400'],
      "display": "flex",
      "flex-direction": "column",
      "gap": "8px",
      "color": "#D32737"
    }
  }, _vm._l(_vm.invalidText_, function (invalidTextValue, invalidTextKey) {
    return _c('c-text', {
      key: invalidTextKey,
      attrs: {
        "d": "flex",
        "align-items": "center",
        "gap": "8px"
      }
    }, [_c('c-box', {
      attrs: {
        "min-height": ['13px', '16px'],
        "min-width": ['13px', '16px'],
        "height": ['13px', '16px'],
        "width": ['13px', '16px']
      }
    }, [_c('inline-svg', {
      attrs: {
        "src": require('@/assets/icons/icon-circle-warn.svg'),
        "height": "100%",
        "width": "100%",
        "fill": "#D32737"
      }
    })], 1), _vm._v(" " + _vm._s(invalidTextValue) + " ")], 1);
  }), 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }