<template>
  <c-form-control
    :is-disabled="isDisabled"
    :is-required="isRequired"
    :is-invalid="isInvalid"
    :margin-bottom="isWithoutMarginBottom ? '0' : ['16px', '24px']"
    :is-read-only="isReadOnly"
    :width="fullWidth ? '100%' : 'inherit'"
  >
    <c-form-label
      v-if="!isWithoutLabel"
      :font-size="['14px', '16px']"
      :color="isInvalid ? 'danger.400' : value ? 'primary.400' : '#555'"
      font-weight="400"
      font-family="Roboto"
      padding-left="8px"
    >
      {{ label }}
      <c-box
        v-if="!isRequired && isRequired !== undefined"
        as="span"
        font-family="Roboto"
        font-weight="400"
        color="#C4C4C4"
        :font-size="['12px', '14px']"
      >
        (Optional)
      </c-box>
    </c-form-label>
    <BaseInputAutocomplete2
      v-model="modelValue"
      :options="options"
      :placeholder="placeholder"
      :is-loading="isLoading"
      :is-invalid="isInvalid"
      :value-label="optionValue"
      :height="getSize.input.height"
      :font-size="getSize.input.fontSize"
      :with-border="withBorder"
      :show-empty-value="showEmptyValue"
      @blur="$emit('blur')"
    />
    <c-form-helper-text
      v-if="isHelper"
      :font-size="['12px', '16px']"
      :font-weight="['400', '400']"
      display="flex"
      gap="8px"
      align-items="center"
      :color="helperTextColor"
    >
      <c-box
        display="flex"
        gap="8px"
        align-items="center"
        :cursor="hasHelperTextClick ? 'pointer' : 'auto'"
        @click="$emit('helper-text-click')"
      >
        <c-box
          v-if="helperTextLogo"
          :min-height="['13px', '16px']"
          :min-width="['13px', '16px']"
          :height="['13px', '16px']"
          :width="['13px', '16px']"
        >
          <inline-svg
            :src="helperTextLogo"
            height="100%"
            width="100%"
            :fill="helperTextColor"
          />
        </c-box>
        {{ helperText }}
      </c-box>
    </c-form-helper-text>

    <c-form-helper-text
      v-if="!isDataEmpty(invalidText) && isInvalid"
      :font-size="['12px', '16px']"
      :font-weight="['400', '400']"
      display="flex"
      flex-direction="column"
      gap="8px"
      color="#D32737"
    >
      <c-text
        v-for="(invalidTextValue, invalidTextKey) in invalidText_"
        :key="invalidTextKey"
        d="flex"
        align-items="center"
        gap="8px"
      >
        <c-box
          :min-height="['13px', '16px']"
          :min-width="['13px', '16px']"
          :height="['13px', '16px']"
          :width="['13px', '16px']"
        >
          <inline-svg
            :src="require('@/assets/icons/icon-circle-warn.svg')"
            height="100%"
            width="100%"
            fill="#D32737"
          />
        </c-box>
        {{ invalidTextValue }}
      </c-text>
    </c-form-helper-text>
  </c-form-control>
</template>

<script>
import { isDataEmpty } from '@/utils/is-data-empty'
import { reqMealGlossaries } from '@/requests/dietela-api/meal-glossaries'
import BaseInputAutocomplete2 from '@/components/elements/base-input-autocomplete-2.vue'
import _ from 'lodash'

export default {
  name: 'InputAutocomplete',
  components: {
    BaseInputAutocomplete2,
  },
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: undefined,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    invalidText: {
      type: [String, Object],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      required: true,
    },
    isHelper: {
      type: Boolean,
      default: false,
    },
    helperText: {
      type: String,
      default: '',
    },
    isWithoutMarginBottom: {
      type: Boolean,
      default: false,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    helperTextType: {
      type: String,
      default: '',
    },
    helperTextColor: {
      type: String,
      default: '',
    },
    isWithoutLabel: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'lg',
    },
    withBorder: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'meal-glossary',
    },
    showEmptyValue: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['blur', 'input', 'update:value'],
  data() {
    return {
      options: [],
      isLoading: false,
      optionValue: '',
    }
  },
  computed: {
    invalidText_() {
      if (typeof this.invalidText === 'string') {
        return [this.invalidText]
      }
      return this.invalidText
    },
    getSize() {
      switch (this.size) {
        case 'md':
          return {
            input: {
              height: ['36px', '50px'],
              fontSize: ['14px', '16px'],
            },
          }
        case 'lg':
        default:
          return {
            input: {
              height: ['48px', '62px'],
              fontSize: ['14px', '18px'],
            },
          }
      }
    },
    helperTextLogo() {
      if (this.helperTextType === 'warn') {
        return require('@/assets/icons/icon-circle-warn.svg')
      }
      if (this.helperTextType === 'questionmark') {
        return require('@/assets/icons/icon-circle-questionmark.svg')
      }
      return ''
    },
    hasHelperTextClick() {
      return this.$listeners['helper-text-click']
    },
    modelValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
        this.$emit('blur')
      },
    },
  },
  watch: {
    modelValue: {
      handler: _.debounce(function(val) {
        switch (this.type) {
          case 'meal-glossary':
            this.optionValue = 'foodName' // foodName is the key of the object in the options array
            this.onSearchMealGlossary(val)
            break
          default:
            break
        }
      }, 500),
    },
  },
  methods: {
    isDataEmpty,
    async onSearchMealGlossary(val) {
      this.options = []
      if (!val) return

      this.isLoading = true

      try {
        const res = await reqMealGlossaries.search(this.$store.getters.axios, {
          q: val,
        })
        this.options = res?.data?.data || []
      } catch (err) {
        console.log('Error fetching meal glossaries:', err)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style scoped></style>
