var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "width": "100%",
      "border-radius": "8px",
      "box-shadow": "0px 4px 8px rgba(0, 0, 0, 0.2)"
    }
  }, [_c('c-flex', {
    attrs: {
      "position": "relative",
      "align-items": "center",
      "justify-content": "space-between",
      "padding-block": "8px",
      "padding-inline": "28px",
      "background-color": "#C7F9E333",
      "border-bottom-width": "1px",
      "botder-bottom-color": "neutral.lightGray"
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "ghost",
      "variant-color": "primary",
      "position": "absolute",
      "left": "2px",
      "height": "24px",
      "min-width": "24px",
      "width": "24px",
      "padding": "0",
      "border-radius": "100%"
    },
    on: {
      "click": function click($event) {
        return _vm.onScrollContent("panel-preview-".concat(_vm.mealTime), 'left', 20, 24, 4);
      }
    }
  }, [_c('c-icon', {
    attrs: {
      "size": "24px",
      "name": "chevron-left"
    }
  })], 1), _c('c-flex', {
    directives: [{
      name: "dragscroll",
      rawName: "v-dragscroll"
    }],
    ref: "panel-preview-".concat(_vm.mealTime),
    attrs: {
      "id": "panel-preview-".concat(_vm.mealTime),
      "flex-grow": "1",
      "justify-content": "flex-start",
      "align-items": "center",
      "gap": "1px",
      "overflow-x": "hidden",
      "scroll-behavior": "smooth"
    }
  }, _vm._l(_vm.totalDays, function (i) {
    return _c('c-flex', {
      key: i,
      attrs: {
        "id": "panel-preview-".concat(_vm.mealTime, "-").concat(i),
        "justify-content": "center",
        "align-items": "center",
        "flex-shrink": "0",
        "width": _vm.selectedDay == i ? '28px' : '24px',
        "height": _vm.selectedDay == i ? '28px' : '24px',
        "border-radius": "100%",
        "font-size": "18px",
        "font-weight": "600",
        "background-color": _vm.selectedDay == i ? 'primary.400' : 'transparent',
        "color": _vm.selectedDay == i ? 'white' : 'neutral.black',
        "cursor": "pointer",
        "_hover": {
          backgroundColor: _vm.selectedDay == i ? 'primary.500' : 'primary.50'
        }
      },
      on: {
        "click": function click($event) {
          return _vm.onChangeDay(i);
        }
      }
    }, [_vm._v(" " + _vm._s(i) + " ")]);
  }), 1), _c('c-button', {
    attrs: {
      "variant": "ghost",
      "variant-color": "primary",
      "position": "absolute",
      "right": "2px",
      "height": "24px",
      "min-width": "24px",
      "width": "24px",
      "padding": "0",
      "border-radius": "100%"
    },
    on: {
      "click": function click($event) {
        return _vm.onScrollContent("panel-preview-".concat(_vm.mealTime), 'right', 20, 24, 4);
      }
    }
  }, [_c('c-icon', {
    attrs: {
      "size": "24px",
      "name": "chevron-right"
    }
  })], 1)], 1), _c('c-box', {
    attrs: {
      "padding": "8px"
    }
  }, [_vm.isLoadingData ? _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "justify-content": "center",
      "align-items": "center",
      "gap": "8px"
    }
  }, [_c('c-spinner', {
    attrs: {
      "thickness": "4px",
      "speed": "0.65s",
      "empty-color": "green.200",
      "color": "primary.400",
      "size": "lg"
    }
  }), _c('c-text', {
    attrs: {
      "color": "primary.400",
      "font-size": ['12px', '16px']
    }
  }, [_vm._v(" Memuat data... ")])], 1) : _vm.recommendations.length > 0 ? _c('ListMenuRecommendation', {
    attrs: {
      "items": _vm.recommendations
    }
  }) : _c('c-flex', {
    attrs: {
      "align-items": "center",
      "gap": "8px"
    }
  }, [_c('c-box', {
    attrs: {
      "min-height": ['14px', '18px'],
      "min-width": ['14px', '18px'],
      "height": ['14px', '18px'],
      "width": ['14px', '18px'],
      "color": "#DA6D06"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-info-2.svg'),
      "height": "100%",
      "width": "100%"
    }
  })], 1), _c('c-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "color": "warning.400"
    }
  }, [_vm._v(" Data belum tersedia ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }